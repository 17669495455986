import React from 'react'
import {Alert, AlertIcon, AlertTitle, AlertDescription, SimpleGrid} from '@chakra-ui/react'
import { xgap } from '../../constants/spacing'

const SendSuccess = () => {
    return (
        <SimpleGrid justifyItems="center" columns={1} gap={xgap}>
        <Alert maxW="100vw"
  status="success"
  variant="subtle"
  flexDirection="column"
  alignItems="center"
  justifyContent="center"
  textAlign="center"
  height="200px"
>
  <AlertIcon boxSize="40px" mr={0} />
  <AlertTitle mt={4} mb={1} fontSize="lg">
    Message sent!
  </AlertTitle>
  <AlertDescription maxWidth="sm">
    Thanks for reaching out. I'll get back to you soon!
  </AlertDescription>
</Alert>
</SimpleGrid>
    )
}

export default SendSuccess
