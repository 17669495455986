import React, {useState, useEffect} from "react";
import { Box, SimpleGrid, Flex, IconButton, Container,Heading, Text, HStack, Grid, GridItem, Input, InputGroup, InputRightElement, InputLeftAddon, InputLeftElement, FormControl, FormLabel, FormErrorMessage, FormHelperText, Button, Textarea} from "@chakra-ui/react";
import { FaRegEnvelope, FaAlignJustify } from "react-icons/fa";
import { mypx, xgap } from "../../constants/spacing";
import SendSuccess from "../alerts/SendSuccess";


export const RealContact = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("")
  const [isSent, setIsSent] = useState(false)
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const msgdata = {
      email,
      message
    };
    console.log(msgdata);
    fetch("api/contact.js", {
      method: "post",
      body: JSON.stringify(msgdata),
    });
    setIsSent(true)
    alert("Thanks for reaching out!")
    
  };

  let handleInputChange = (e) => {
    let inputValue = e.target.value
    setMessage(inputValue)
  }
  return(
  
    <Box m="0 auto" w="md">
      { isSent ?<SimpleGrid columns={1} gap={xgap}> <SendSuccess /></SimpleGrid> : 
    <SimpleGrid justifyItems={['center',null,null,null,'stretch']} columns={1} gap={xgap}>
      <form>
        <FormControl id="email">
          <InputGroup size="lg">
            <Input onChange={(e)=> setEmail(e.target.value)} borderRadius="none" textAlign="center" placeholder="email@email.com" focusBorderColor="transparent" type="email" />
            <InputLeftElement >
              <IconButton pointerEvents="none" border="1px" size="lg" icon={<FaRegEnvelope />} />
            </InputLeftElement>
          </InputGroup>
        </FormControl>
        <FormControl id="message">
          <Textarea borderRadius="none" resize="none"
            value={message}
            textAlign="center"
            onChange={handleInputChange}
            placeholder="Type your message here..."
            />
  
        </FormControl>
        <Flex justify="center" w="100%">
        <Button type="submit" onClick={handleSubmit} justifySelf="center">Send</Button></Flex>
      </form>
    </SimpleGrid>}
    
    </Box>
  )
}



const ContactForm = ({data}) => {
  return (

    <>
   <Grid border="1px solid" alignItems="center" justifyItems="start" templateColumns="(2, .25fr .75fr)" templateRows="1">
       <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={1}>
       <IconButton borderRight="1px" size="lg" fontSize="2xl" color="black" icon={<FaRegEnvelope />} />
       </GridItem>
       <GridItem  colStart={2} colEnd={2} rowStart={1} rowEnd={1}>
            <Text justifySelf="start">mail@hollandhames.com</Text>
       </GridItem>
   </Grid>
   
   {/* <Grid border="1px solid" alignItems="center" justifyItems="start" templateColumns="(2, .25fr .75fr)" templateRows="1">
       <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={1}>
       <IconButton borderRight="1px" size="lg" w="100px" height="100px" fontSize="6xl" color="black" icon={<FaRegEnvelope />} />
       </GridItem>
       <GridItem colStart={2} colEnd={2} rowStart={1} rowEnd={1}>
         <Flex alignContent="flex-end" w="100%">
            <Text fontSize={["xl",null,null,null,"2xl"]}justifySelf="start">Mail@HollandHames.com</Text>
            </Flex>
       </GridItem>
   </Grid> */}
    {/* <Flex  mb={mypx} mt={10} align='center' border='1px solid black'>
    <HStack>
        <IconButton p={["2",null,null,null,"7"]} fontSize={["lg",null,null,null,"5xl"]} fontWeight="light" borderRight="1px solid black" icon={<FaRegEnvelope />} />
     
     
        <Container d="flex" justify="center" align="center" fontSize={["xl","3xl"]} px={["2",null,null,null,"20"]}><Text>Mail@HollandHames.com</Text></Container>
      
        </HStack>
    </Flex> */}
    </>
  );
};

export default ContactForm;

